interface IConfig {
  [key: string]: string,
  URL_BASE: string,
  OKTA_DOMAIN: string,
  CLIENT_ID: string,
  REQUIRE_VERIFICATION: string,
  SERVICES_URL_BASE: string,
  POWERSCORE_REDIRECT: string,
  PE_REDIRECT: string,
  PE_URL_BASE: string,
  REQUIRE_RECAPTCHA: string,
  RECAPTCHA_SITE_KEY: string,
  SQE_REDIRECT: string
}

interface JsonFile {
  [key: string]: string
}

import axios from 'axios'
let Configuration: IConfig = {} as IConfig

const InitConfiguration = async (): Promise<void> => {
  const response = await axios.get('/config.values.json')
  const configValues: JsonFile = response.status === 404 ? {} : response.data
  const config = {} as IConfig
  for (const value in configValues) {
    config[value] = parse(configValues[value], process.env[`VUE_APP_${value}`])
  }
  Configuration = config
}

function parse(value: any, fallback: any) {
  if (typeof value === 'undefined' || value === '') {
    return fallback
  }
  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value)
    case 'number':
      return JSON.parse(value)
    default:
      return value
  }
}

export { InitConfiguration, Configuration }
