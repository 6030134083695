
import { onMounted } from 'vue'
import { createSession } from '@/api/SessionApi'
import { Configuration } from '@/config/config'
import { Skin } from '@/utils/SkinTools'
export default {
  setup() {
    onMounted(async () => {
      const ssuUrl = await createSession(buildRedirectUrl(), false, Skin.pe, true)
      location.assign(ssuUrl)
    })

    const buildRedirectUrl = (): string => {
      const queryParams = window.location.search
      const redirectUrl = Configuration.PE_REDIRECT

      if (!queryParams) {
        return redirectUrl
      }

      const currentQueryParams = new URLSearchParams(queryParams)
      const returnUrl = currentQueryParams.get('ReturnUrl')

      if (!returnUrl) {
        return redirectUrl
      }

      const peUrl = new URL(Configuration.PE_URL_BASE)
      peUrl.pathname = returnUrl

      const updatedRedirectUrl = new URL(redirectUrl)
      updatedRedirectUrl.searchParams.set('RelayState', peUrl.toString())
      return decodeURIComponent(updatedRedirectUrl.toString())
    }
  }
}
