import axios from 'axios'
import { GetUrlString } from './ApiBase'
import axiosRetry from 'axios-retry'
import { logInfoMessage } from '@/logging'
import { Session } from '@/types/session'
import { Skin } from '@/utils/SkinTools'

async function getSession(sessionId: string, localId: string, retry: boolean = true): Promise<Session> {
  if(retry) {
    axiosRetry(axios, {
      retries: 2,
      retryDelay: (retryCount) => {
        logInfoMessage(`retry attempt: ${retryCount}`)
        return retryCount * 2000
      }
    })
  }

  const response = await axios.get(GetUrlString('ssu/sessions/:sessionId', {sessionId: sessionId, local: localId}))
  if(response.status !== 200) {
    throw new Error('There was an error retrieving the session.')
  }

  const retVal = response.data as Session
  if(response.data.data) {
    retVal.data = JSON.stringify(response.data.data)
  }

  return retVal
}

async function setSessionLocalId(sessionId: string, nonce: string, localId: string) {
  return await axios.put(GetUrlString('anon/sessions/:sessionId', {sessionId: sessionId, nonce: nonce}), {localId: localId})
}

async function createSession(redirectUrl: string, isSalesforce: boolean, skin: Skin, signinOnly: boolean): Promise<string> {
  const response = await axios.post(GetUrlString('anon/sessions'), {
    redirectUrl,
    isSalesforce,
    skin,
    signinOnly
  })
  return response.data.toString()
}

export { getSession, setSessionLocalId, createSession }
